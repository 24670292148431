// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBqNWYjIxW6FJ2tOA0d4s5vhHFoRObOILs",
  authDomain: "localchat-7d6ad.firebaseapp.com",
  projectId: "localchat-7d6ad",
  storageBucket: "localchat-7d6ad.appspot.com",
  messagingSenderId: "557511020419",
  appId: "1:557511020419:web:cc25bf64d5665da77c70b6",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore, firebaseConfig };

