// ChatApp.js
import React, { useState, useEffect } from 'react';
import { firestore } from './firebase';
import { collection, addDoc, orderBy, onSnapshot, query } from 'firebase/firestore';

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [hasEntered, setHasEntered] = useState(false);

  useEffect(() => {
    const q = query(collection(firestore, 'messages'), orderBy('timestamp'));

    const unsubscribe = onSnapshot(q, snapshot => {
      const messages = snapshot.docs.map(doc => {
        const data = doc.data();
        if (data && data.timestamp && data.text && data.userName) {
          return {
            id: doc.id,
            text: data.text,
            userName: data.userName,
            timestamp: data.timestamp.toDate(),
          };
        }
        return null;
      }).filter(message => message !== null);

      setMessages(messages);
      
    });

    return () => unsubscribe();
  }, []);

  const sendSystemMessage = (message) => {
    addDoc(collection(firestore, 'messages'), {
      text: message,
      userName: 'Sistem',
      timestamp: new Date(),
    });
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      const messageData = {
        text: newMessage,
        userName: userName,
        timestamp: new Date(),
      };

      await addDoc(collection(firestore, 'messages'), messageData);
      setNewMessage('');

      // Kullanıcı adı varsa ve daha önce hoş geldin mesajı gönderilmediyse gönder
      if (userName && !hasEntered) {
        sendSystemMessage(`🎉 ${userName} sohbete katıldı! 🎉`);
        setHasEntered(true);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleNameSubmit = () => {
    if (userName.trim() !== '') {
      setHasEntered(true);
      sendSystemMessage(`${userName} sohbete katıldı`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.messageContainer}>
        {messages.map(message => (
          <div key={message.id} style={message.userName === 'Sistem' ? styles.systemMessage : styles.message}>
            {message.userName !== 'Sistem' && <strong style={styles.userName}>{message.userName}</strong>}
            <p style={styles.messageText}>{message.text}</p>
            <small style={styles.timestamp}>{message.timestamp.toString()}</small>
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        {hasEntered ? (
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="Mesajınızı yazın"
              value={newMessage}
              onChange={e => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              style={styles.input}
            />
            <button onClick={handleSendMessage} style={styles.button}>Gönder</button>
          </div>
        ) : (
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="İsminizi girin"
              value={userName}
              onChange={e => setUserName(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleNameSubmit()}
              style={styles.input}
            />
            <button onClick={handleNameSubmit} style={styles.button}>Giriş Yap</button>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  messageContainer: {
    flex: '1',
    overflowY: 'auto',
    padding: '20px',
  },
  message: {
    backgroundColor: '#e0f7fa',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
  },
  systemMessage: {
    fontStyle: 'italic',
    color: '#888',
    textAlign: 'center',
    marginBottom: '20px',
  },
  userName: {
    marginRight: '8px',
  },
  messageText: {
    margin: '0',
  },
  timestamp: {
    color: '#888',
    fontSize: '0.8rem',
  },
  inputContainer: {
    borderTop: '1px solid #ccc',
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
  },
  inputGroup: {
    display: 'flex',
    flex: '1',
  },
  input: {
    flex: '1',
    padding: '8px',
    marginRight: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '8px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
  },
};

export default ChatApp;
